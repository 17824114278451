@tailwind base;
@tailwind components;
@tailwind utilities;

/* Churnkey styling using #ck-app */
#ck-app {
  --ckPrimaryColor: #0078d450;
  --tw-ring-color: #0078d4 !important;

  .border-client-primary {
    border-color: #0078d4;
  }
  .text-client-primary {
    color: #0078d4;
  }
  .border-text-client-primary {
    border-color: #0078d4;
  }
  .bg-client-primary {
    background-color: #0078d4;
  }
  .bg-client-primary-middle {
    background-color: #0078d450;
  }
  .border-primary-middle {
    border-color: #0078d450;
  }
  .text-client-primary-middle {
    color: #0078d450;
  }
  .bg-client-primary-light {
    background-color: #0078d411;
  }
  .border-primary-light {
    border-color: #0078d411;
  }
  [type='text']:focus,
  [type='email']:focus,
  [type='url']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='date']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='week']:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    --tw-ring-color: #0078d4;
    border-color: #0078d4;
  }
}
